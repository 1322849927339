import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import g from "glamorous";
import Layout from "../components/layout";
import Img from "gatsby-image";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import AdresseSidebar from "../components/AdresseSidebar";
import ContactSidebar from "../components/ContactForm";
import VideoBlock from "../components/VideoBlock";
import BioAccueil from "../components/BioAccueil";
import TemoignagesAccueil from "../components/TemoignagesAccueil";
import NewsBlock from "../components/NewsBlock";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaPlus } from "react-icons/fa";

export default function Homepage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { logo, lvpa, protheses, hydrafacial, onda, emsculpt, lasemd } = data;

  const Wrapper = g.main({
    maxWidth: `1200px`,
    margin: `3em auto 2.5em`,
    "@media(max-width: 767px)": {
      margin: `1em auto 2.5em`,
    },
  });

  const LogoWrapper = g.div({
    backgroundColor: `white`,
    marginBottom: `3em`,
    borderRadius: `6px`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
    padding: `1.25em 1em 1.5em .8em`,
    "@media(max-width: 767px)": {
      marginBottom: `.2em`,
    },
  });

  const Adresse = g.div({
    fontSize: `.8em`,
    lineHeight: `1.25em`,
    color: `#3c4d68`,
    opacity: `.75`,
    display: `flex`,
    justifyContent: `flex-end`,
    "@media(max-width: 767px)": {
      display: `none`,
    },
  });

  const H1 = g.h1({
    margin: `.8em 0 .1em`,
    color: `#3c4d68`,
    fontSize: `1.64em`,
    fontWeight: `500`,
    textAlign: `right`,
    lineHeight: `1.25em`,
    letterSpacing: `0.028em`,
    "@media(max-width: 767px)": {
      fontSize: `1.3em`,
      margin: `0 0 .1em`,
      textAlign: `left`,
    },
  });

  const Membre = g.div({
    fontSize: `1.02em`,
    lineHeight: `1.4em`,
    fontWeight: `500`,
    paddingRight: `1px`,
    textAlign: `right`,
    "@media(max-width: 767px)": {
      fontSize: `0.9em`,
      paddingRight: `0`,
      textAlign: `left`,
    },
  });

  const H2 = g.h2({
    position: `relative`,
    fontSize: `32px`,
    fontWeight: `500`,
    marginTop: `0.2em`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `205px`,
      border: `.063em solid #3c4d68`,
      display: `inline-block`,
      width: `610px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .5em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Card = g.div({
    backgroundColor: `white`,
    marginTop: `0`,
    marginBottom: `2em`,
    borderRadius: `6px`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
    transition: `all 0.15s ease-in-out`,
    ":hover": {
      border: `1px solid rgba(73,105,126,0.18)`,
      boxShadow: `0 1px 10px rgba(0,0,0,.26)`,
      transition: `all 0.15s ease-in-out`,
      transform: `translateY(-2px)`,
    },
    "@media(max-width: 767px)": {
      marginBottom: `1.5em`,
    },
  });

  const Title = g.h3({
    padding: `0 .5em 0 .82em`,
    margin: `.7em 0 .3em`,
    font: `700 18px/1.35em -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  });

  const Text = g.p({
    padding: `0 .5em 0 .8em`,
    marginBottom: `.6em`,
    color: `#1f1f1f`,
  });

  const PlusWrapper = g.div({
    display: `block`,
    textAlign: `right`,
    color: `#3c4d68`,
    margin: `0 1.5em .85em`,
    fontSize: `.8em`,
  });

  return (
    <Layout>
      <g.Div margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>Dr Cédric Kron | Chirurgien esthétique à Paris 17</title>
          <meta
            name="description"
            content="Chirurgien esthétique à Paris, le Dr Kron est un chirurgien qualifié en chirurgie esthétique, plastique et reconstructrice ainsi qu'en médecine esthétique."
          />
          <html lang="fr" />
          <link
            rel="alternate"
            hreflang="en"
            href="https://www.dr-kron.com/en/"
          />
          <meta property="og:url" content="https://www.dr-kron.com/" />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Dr Cédric Kron | Chirurgien esthétique à Paris 17"
          />
          <meta
            property="og:description"
            content="Chirurgien esthétique à Paris, le Dr Kron est un chirurgien qualifié en chirurgie esthétique, plastique et reconstructrice ainsi qu'en médecine esthétique."
          />
          <meta
            property="og:image"
            content="https://www.dr-kron.com/dr-cedric-kron-chirurgie-esthetique-paris.jpg"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="376" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="fb:app_id" content="402235436975681" />
        </Helmet>
        <Menu pathEn="/en/" />
        <Wrapper>
          <Container
            css={{
              paddingRight: `12px !important`,
              paddingLeft: `12px !important`,
            }}
          >
            <Row>
              <Col>
                <LogoWrapper>
                  <Row>
                    <Col span={{ xs: 12 / 12, lg: 25 / 100 }}>
                      <Img
                        fluid={logo.childImageSharp.fluid}
                        alt="Logo du Dr Cédric Kron, chirurgien esthétique à Paris"
                        css={{
                          marginBottom: `.5em`,
                          "@media(max-width: 767px)": {
                            margin: `10px`,
                          },
                        }}
                      />
                    </Col>
                    <Col
                      span={{ xs: 12 / 12, lg: 75 / 100 }}
                      css={{
                        paddingRight: `6px !important`,
                        paddingLeft: `8px !important`,
                      }}
                    >
                      <Adresse>
                        Dr Cédric Kron, chirurgien esthétique à Paris
                        <br />
                        57 avenue de Villiers - 75017 Paris
                        <br />
                        Tél. : 01 45 62 85 00
                      </Adresse>
                      <H1>
                        Chirurgien Esthétique à Paris - Médecine et Chirurgie
                        Esthétique
                      </H1>
                      <Membre>
                        <Link
                          to="/actualites/nomination-academie-nationale-chirurgie/"
                          css={{
                            color: `#3c4d68`,
                          }}
                        >
                          Membre de l’Académie Nationale de Chirurgie
                        </Link>
                      </Membre>
                    </Col>
                  </Row>
                </LogoWrapper>
              </Col>
            </Row>

            <Row>
              <Col
                span={{ sm: 12 / 12, lg: 74 / 100 }}
                css={{ backgroundcolor: `white` }}
              >
                <section>
                  <H2>Interventions</H2>
                  <Row>
                    <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                      {" "}
                      <Card>
                        <Link to="/visage/lifting-visage-paris/">
                          <Img
                            fluid={lvpa.childImageSharp.fluid}
                            alt="Lifting du visage à Paris"
                            css={{
                              marginBottom: `.5em`,
                              borderRadius: `6px 6px 0 0`,
                            }}
                          />
                          <Title>Lifting du visage</Title>
                        </Link>
                        <Text>
                          Le lifting du visage LVPA est une technique de
                          chirurgie esthétique qui permet de corriger
                          naturellement et durablement les relâchements du
                          visage, avec des cicatrices invisibles et une
                          resocialisation en moins d'une semaine.
                        </Text>
                        <Link
                          to="/visage/lifting-visage-paris/"
                          title="Informations sur le lifting du visage LVPA mis au point par le Dr Kron à Paris"
                        >
                          <PlusWrapper>
                            <FaPlus
                              css={{
                                fontSize: `1em`,
                                padding: `0.45em .3em 0em`,
                              }}
                            />
                            En savoir plus
                          </PlusWrapper>
                        </Link>
                      </Card>
                    </Col>

                    <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                      <Card>
                        <Link to="/seins/protheses-mammaires/">
                          <Img
                            fluid={protheses.childImageSharp.fluid}
                            alt="Augmentation mammaire par prothèses à Paris"
                            css={{
                              marginBottom: `.5em`,
                              borderRadius: `6px 6px 0 0`,
                            }}
                          />
                          <Title>Augmentation mammaire par prothèses</Title>
                        </Link>
                        <Text>
                          Une augmentation mammaire par prothèses doit embellir
                          la forme et la taille d'une poitrine tout en proposant
                          le résultat le plus naturel possible, conforme au
                          désir de la patiente et à sa conception de la
                          féminité.
                        </Text>
                        <Link
                          to="/seins/protheses-mammaires/"
                          title="Informations sur l'augmentation mammaire par prothèses à Paris"
                        >
                          <PlusWrapper>
                            <FaPlus
                              css={{
                                fontSize: `1em`,
                                padding: `0.45em .3em 0em`,
                              }}
                            />
                            En savoir plus
                          </PlusWrapper>
                        </Link>
                      </Card>
                    </Col>

                    <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                      <Card>
                        <Link to="/visage/hydrafacial/">
                          <Img
                            fluid={hydrafacial.childImageSharp.fluid}
                            alt="Traitement HydraFacial réalisé dans le cabinet de Paris"
                            css={{
                              marginBottom: `.5em`,
                              borderRadius: `6px 6px 0 0`,
                            }}
                          />
                          <Title>HydraFacial</Title>
                        </Link>
                        <Text>
                          HydraFacial est un traitement esthétique unique qui
                          combine nettoyage, exfoliation et hydratation de la
                          peau. Complètement indolore, cette procédure en trois
                          étapes permet d’obtenir des résultats très
                          significatifs, immédiats et durables.
                        </Text>
                        <Link
                          to="/visage/hydrafacial/"
                          title="Informations sur le protocole de régénération de la peau Keybody Skin"
                        >
                          <PlusWrapper>
                            <FaPlus
                              css={{
                                fontSize: `1em`,
                                padding: `0.45em .3em 0em`,
                              }}
                            />
                            En savoir plus
                          </PlusWrapper>
                        </Link>
                      </Card>
                    </Col>
                    <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                      <Card>
                        <Link to="/silhouette/onda-coolwaves/">
                          <Img
                            fluid={onda.childImageSharp.fluid}
                            alt="ONDA Coolwaves : Traitement définitif de la cellulite, des graisses localisées et du relâchement cutané"
                            css={{
                              marginBottom: `.5em`,
                              borderRadius: `6px 6px 0 0`,
                            }}
                          />
                          <Title>ONDA Coolwaves</Title>
                        </Link>
                        <Text>
                          Fondé sur l’action ciblée de micro-ondes
                          électromagnétiques, le traitement ONDA élimine la
                          cellulite, réduit les amas de graisses localisés et
                          raffermit la peau en toute sécurité, d’une manière
                          définitive, efficace et non invasive.
                        </Text>
                        <Link
                          to="/silhouette/onda-coolwaves/"
                          title="Traitement ONDA Coolwaves à Paris 17e"
                        >
                          <PlusWrapper>
                            <FaPlus
                              css={{
                                fontSize: `1em`,
                                padding: `0.45em .3em 0em`,
                              }}
                            />
                            En savoir plus
                          </PlusWrapper>
                        </Link>
                      </Card>
                    </Col>
                    <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                      <Card>
                        <Link to="/silhouette/emsculpt/">
                          <Img
                            fluid={emsculpt.childImageSharp.fluid}
                            alt="Sculpter son corps avec des séances d'EMSculpt à Paris"
                            css={{
                              marginBottom: `.5em`,
                              borderRadius: `6px 6px 0 0`,
                            }}
                          />
                          <Title>EMSculpt</Title>
                        </Link>
                        <Text>
                          EMSculpt est un traitement innovant et non-invasif de
                          remodelage du corps. Ce traitement sculpte
                          efficacement la ceinture abdominale et tonifie les
                          fesses par une double action de renforcement
                          musculaire et de suppression des graisses.
                        </Text>
                        <Link
                          to="/silhouette/emsculpt/"
                          title="Informations sur le traitement de renforcement de la ceinture abdominale et de suppression des
                          graisses EMSculpt"
                        >
                          <PlusWrapper>
                            <FaPlus
                              css={{
                                fontSize: `1em`,
                                padding: `0.45em .3em 0em`,
                              }}
                            />
                            En savoir plus
                          </PlusWrapper>
                        </Link>
                      </Card>
                    </Col>
                    <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                      <Card>
                        <Link to="/peau/lasemd-ultra/">
                          <Img
                            fluid={lasemd.childImageSharp.fluid}
                            alt="LaseMD Ultra : un laser intelligent optimisé pour tous les patients"
                            css={{
                              marginBottom: `.5em`,
                              borderRadius: `6px 6px 0 0`,
                            }}
                          />
                          <Title>LaseMD Ultra</Title>
                        </Link>
                        <Text>
                          Combinant un laser fractionné de dernière génération
                          et des ampoules de cosméceutique, LaseMD Ultra est un
                          laser intelligent qui permet de traiter les taches et
                          lésions pigmentaires tout pour corrigeant les signes
                          du vieillissement.
                        </Text>
                        <Link
                          to="/peau/lasemd-ultra/"
                          title="Informations sur le laser LaseMd Ultra à Paris"
                        >
                          <PlusWrapper>
                            <FaPlus
                              css={{
                                fontSize: `1em`,
                                padding: `0.45em .3em 0em`,
                              }}
                            />
                            En savoir plus
                          </PlusWrapper>
                        </Link>
                      </Card>
                    </Col>
                    <Col>
                      <PlusWrapper
                        css={{
                          fontSize: `1.6em`,
                          margin: `0 0 1.4em 0`,
                          "@media(max-width: 767px)": {
                            fontSize: `1.35em`,
                            marginTop: `-0.3em`,
                          },
                        }}
                      >
                        <Link
                          to="/indications/"
                          css={{ textureDecoration: `none`, color: `#3c4d68` }}
                        >
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.1em .35em 0em`,
                              transform: `translateY(4px)`,
                            }}
                          />
                          Trouver l'intervention qui correspond à vos attentes
                          esthétiques
                        </Link>{" "}
                        <br />
                        <br />
                        <Link
                          to="/interventions/"
                          css={{
                            textureDecoration: `none`,
                            color: `#3c4d68`,
                          }}
                        >
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.1em .35em 0em`,
                              transform: `translateY(4px)`,
                            }}
                          />
                          Voir toutes les interventions de médecine et chirurgie
                          esthétique
                        </Link>
                      </PlusWrapper>
                    </Col>
                  </Row>
                </section>
              </Col>

              <Col span={{ sm: 12 / 12, lg: 26 / 100 }}>
                <aside>
                  <NewsBlock />
                  <ContactSidebar about="les traitements de médecine et de chirurgie esthétique pratiqués au cabinet du Dr Kron à Paris" />
                  <AdresseSidebar />
                  <VideoBlock />
                </aside>
              </Col>
            </Row>
            <BioAccueil />
            <TemoignagesAccueil />
          </Container>
        </Wrapper>
        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query HomepageQuery {
    logo: file(relativePath: { eq: "images/logo-dr-cedric-kron-2.png" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 248) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    lvpa: file(relativePath: { eq: "images/lifting-visage-lvpa.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    protheses: file(
      relativePath: { eq: "images/augmentation-mammaire-protheses.jpg" }
    ) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hydrafacial: file(relativePath: { eq: "images/hydrafacial-a-paris.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    onda: file(relativePath: { eq: "images/onda-coolwaves.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    emsculpt: file(relativePath: { eq: "images/emsculpt-paris.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    lasemd: file(relativePath: { eq: "images/lasemd-ultra.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
